import React from 'react';

export const ThemeContext = React.createContext({
  theme: {
    color: { colorFrom: '#5200be', colorVia: '#9000ff', colorTo: '#002aff' },
    secondaryColor: '#ebddf8',
  },
  setTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = React.useState({
    color: { colorFrom: '#5200be', colorVia: '#9000ff', colorTo: '#002aff' },
    secondaryColor: '#ebddf8',
    imageIndex: 0
  });

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
